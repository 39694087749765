import axios from "axios";
import { config } from "../config";
import authHeader from "./auth-header";

const API_URL = config.API_URL;

class TrafficService {
  // Get IP Statistics
  getStatistics(url, data) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
      params: {
        "search": data.search,
        "currentPage": data.currentPage,
        "perPage": data.perPage
      },
    });
  }
  // Get volume statistics
  getVolumeStatistics(url) {
    return axios.get(API_URL + url, {
      headers: authHeader(),
    });
  }
}
export default new TrafficService();
