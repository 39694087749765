<template>
  <Spinner v-if="isLoading"></Spinner>
  <div class="mt-5 p-5" v-else>
    <div class="d-flex justify-content-end">
      <span class="text-white text-center mr-2">BGP SERVER STATUS</span>
      <span v-if="bgpStatus" class="online"></span>
      <span v-else class="offline"></span>
    </div>
    <h1 class="text-center text-white">IP Statistics</h1>
    <hr>
    <h2 class="text-white">Connections</h2>
    <div class="d-flex justify-content-end w-100 mx-3 mb-5">
      <h5 class="p-0 my-3 mx-3">
        <font-awesome-icon icon="search" class="text-white" />
      </h5>
      <b-nav pills>
        <b-nav-form class="mr-2">
          <b-form-input
            :placeholder="'e.g 127.0.0.1'"
            v-model="ip"
            type="search"
            @input="handleSearchChange"
            class="mr-1"
          ></b-form-input>
        </b-nav-form>
      </b-nav>
    </div>
    <b-row class="statistics statistics-table" v-if="total">
     <b-table
  id="statistics-table"
  :items="statistics"
  :per-page="perPage"
  :current-page="currentPage"
  :fields="fields"
  ref="statistics-table"
  hover
  dark
  class="small nowrap text-center"
>
  <!-- Custom rendering for the 'no' column -->
  <template #cell(no)="data">
    {{ data.index + 1 + (currentPage - 1) * perPage }}
  </template>

  <!-- Custom rendering for the 'ipProtocol' column -->
  <template #cell(ipProtocol)="data">
    <span v-if="data.item.ipProtocol === 'Other'">
      {{ data.item.proto }}
    </span>
    <span v-else>
      {{ data.item.ipProtocol }}
    </span>
  </template>
</b-table>

      <b-row class="text-center m-3 d-block">
        <b-pagination
          v-model="currentPage"
          align="center"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="statistics-table"
        ></b-pagination>
      </b-row>
    </b-row>
    <b-row v-else>
      <p class="text-center text-white mt-5">No IP statistics</p>
    </b-row>

    <br/>
    <br/>
    <h3 class="text-white mb-2">Volume</h3>
    <b-row class="statistics statistics-table" v-if="volumeStatistics?.length">
      <b-table
        id="volumes-table"
        :items="volumeStatistics"
        :fields="volumeFields"
        ref="volumes-table"
        hover
        dark
        class="small nowrap text-center"
      >
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
    </b-row>
    <b-row v-else>
      <p class="text-center text-white mt-5">No Volume statistics</p>
    </b-row>
  </div>
</template>
<script>
import TrafficService from "../../services/traffic.service";
import FlowSpecService from "../../services/flow-spec.service";
import Spinner from "../../components/spinner/spinner.vue";
import SettingService from "../../services/setting.service";

export default {
  name: "IpStatistics",
  components: {
    Spinner,
  },
  data() {
    return {
      ip: "",
      polling: null,
      isLoading: false,
      statistics: [],
      volumeStatistics: [],
      total: 0,
      currentPage: 1,
      perPage: 10,
      bgpStatus: false,
      showModal: false,
      modalData: "",
      peers: [],
      fields: [
        { key: "no", label: "No" },
        { key: "targetIpAddr", label: "Destination IP" },
        { key: "sourcePort", label: "Source Port" },
        { key: "destinationPort", label: "Destination Port" },
        { key: "ipProtocol", label: "Protocol" },
        { key: "uniqueConnections", label: "Unique Connections" },
        { key: "connectionRate", label: "Connection Rate(s)" },
      ],
      volumeFields: [
        { key: "tcp", label: "Top TCP Volume" },
        { key: "udp", label: "Top UDP Volume" },
        { key: "other", label: "Top Other Volume" }
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    currentPage(newPage) {
      this.getStatistics(newPage);
    },
  },
  mounted() {
    this.isLoading = true;
    this.getSetting();
    this.getBGPServerStatus();
    this.getStatistics();
    this.getVolumeStatistics();
  },
  created() {
    this.createTimeInterval();
  },
  methods: {
    logOut() {
      localStorage.removeItem("user");
      this.$router.push("/");
    },
    async getStatistics(page = this.currentPage) {
      const url = "ip-statistics";
      const response = await TrafficService.getStatistics(url, {
        currentPage: page,
        perPage: this.perPage,
        search: this.ip,
      });
      if (response.status == 200) {
        this.statistics = response.data.data;
        this.total = response.data.totalCount;
        this.isLoading = false;
      }
    },
    async getVolumeStatistics() {
      const url = "volume-statistics";
      const response = await TrafficService.getVolumeStatistics(url);
      this.volumeStatistics = [];
      if (response.status == 200) {
        for (let i=0; i < response?.data?.tcp?.length; i++) {
          this.volumeStatistics.push({
              tcp: response?.data?.tcp[i]?.Ip ? response?.data?.tcp[i]?.Ip + ' ( ' + parseFloat(response?.data?.tcp[i]?.packetLength / (1024*1024))?.toFixed(4) + ' MB)': '',
              udp: response?.data?.udp[i]?.Ip ? response?.data?.udp[i]?.Ip + ' ( ' + parseFloat(response?.data?.udp[i]?.packetLength / (1024*1024))?.toFixed(4) + ' MB)': '',
              other: response?.data?.other[i]?.Ip ? response?.data?.other[i]?.Ip + ' ( ' + parseFloat(response?.data?.other[i]?.packetLength / (1024*1024))?.toFixed(4) + ' MB)': ''
            }
          )
        }
      }
    },
    async getBGPServerStatus() {
      const url = "flowspec/bgp";
      this.isLoading = true;
      
      try {
        const response = await FlowSpecService.getBGPServerStatus(url);
        if (response) {
          if ("bgp" in response.data) {
            this.bgpStatus = true;
            this.peers = response.data.peers;
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;

    },
    async getSetting() {
      const url = "settings";
      const response = await SettingService.getSetting(url);
      if (response.status == 200 && response.data.websiteName) {
        this.$store.dispatch(
          "setting/setInterval",
          response.data.report.interval
        );
      }
    },
    async createTimeInterval() {
      let time = 3000;
      let timeInterval = localStorage.getItem("bgp_interval");
      if (timeInterval) time = parseInt(timeInterval);
      this.polling = setInterval(() => {
        if (!this.isLoading){
          this.getStatistics();
          this.getVolumeStatistics();
        }
      }, time);
      
    },
    handleSearchChange() {
      this.getStatistics();
    },
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
};
</script>
<style scoped>
.statistics-table {
  max-width: 100vw;
  overflow: auto;
}
.online {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offline {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>
